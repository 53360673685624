import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contato.css';

function Contato() {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name.trim() === '' || message.trim() === '') {
      setStatus('Por favor, preencha todos os campos.');
      setShowPopup(true);
      return;
    }

    const templateParams = {
      from_name: name,
      message: message,
      to_email: 'bandaexcelsu@gmail.com',
    };

    emailjs.send( process.env.REACT_APP_EMAILJS_SERVICE_ID,
                  process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
                  templateParams, 
                  process.env.REACT_APP_EMAILJS_USER_ID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setStatus('E-mail enviado com sucesso!');
        setShowPopup(true);
        setName('');
        setMessage('');
      }, (err) => {
        console.log('FAILED...', err);
        setStatus('Erro ao enviar e-mail.');
        setShowPopup(true);
      });
  };

  const closePopup = () => {
    setShowPopup(false);
    setStatus('');
  };

  return (
    <div className="contato-container">
      <h1>Nos envie um e-mail por aqui.</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Digite Seu nome"
          required
        />
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Escreva sua mensagem aqui"
          required
        />
        <button type="submit">Enviar</button>
      </form>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>{status}</p>
            <button onClick={closePopup}>Fechar</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contato;
