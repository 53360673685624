import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <h1 className="navbar-title"><Link to="/">Excelsu</Link></h1>
      <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <li><Link to="/" onClick={closeMenu}>Home</Link></li>
        <li><Link to="/sobre" onClick={closeMenu}>Sobre</Link></li>
        <li><Link to="/fotos" onClick={closeMenu}>Fotos</Link></li>
        <li><Link to="/contato" onClick={closeMenu}>Contato</Link></li>
        <li><Link to="/chat" onClick={closeMenu}>Chat</Link></li>
      </ul>
      <div className="hamburger" onClick={toggleMenu}>
        <div className={`line ${isOpen ? 'open' : ''}`}></div>
        <div className={`line ${isOpen ? 'open' : ''}`}></div>
        <div className={`line ${isOpen ? 'open' : ''}`}></div>
      </div>
    </nav>
  );
};

export default Navbar;
