import React, { useState, useEffect } from 'react';
import { database } from '../firebase';
import { ref, onValue, push, set } from 'firebase/database';
import './Chat.css';

const Chat = () => {
    
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [username, setUsername] = useState('');

  useEffect(() => {
    const messagesRef = ref(database, 'messages');
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const parsedMessages = Object.values(data);
        setMessages(parsedMessages);
      }
    });
  }, []);

  const sendMessage = () => {
    if (newMessage.trim() === '' || username.trim() === '') {
      alert('Por favor, preencha seu nome e a mensagem.');
      return;
    }

    const messagesRef = ref(database, 'messages');
    const newMessageRef = push(messagesRef);
    set(newMessageRef, {
      text: newMessage,
      user: username,
      timestamp: Date.now()
    });
    setNewMessage('');
  };

  return (
    <div className="chat-container">
      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index} className="message">
            <strong>{msg.user}: </strong>{msg.text}
          </div>
        ))}
      </div>
      <div className="input-container">
        <input
          type="text"
          placeholder="Seu nome"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="text"
          placeholder="Digite sua mensagem"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button onClick={sendMessage}>Enviar</button>
      </div>
    </div>
  );
};

export default Chat;
