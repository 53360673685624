import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p className="text-midias">Midias Sociais e contatos</p>
      <div className="social-media">
        <a href="https://www.instagram.com/bandaexcelsu" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
        <a href="https://www.youtube.com/@bandaexcelsu" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
        <a href="mailto:bandaexcelsu@gmail.com"><FontAwesomeIcon icon={faEnvelope} /></a>
      </div>
      <p className="devPor">Desenvolvido por Gleilson Alves</p>
    </footer>
  );
}

export default Footer;
