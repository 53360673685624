import React from 'react';
import './Sobre.css';
import video from '../assets/video.mp4';

function Sobre() {
  return (
    <div className="sobre-container">
      <div className="sobre-content">
        <video controls autoPlay muted loop className="sobre-video">
          <source src= {video} type="video/mp4" />
          Seu navegador não suporta o elemento de vídeo.
        </video>
        <div className="text-container">
          <p>
            Somos uma banda de rock cristão comprometida em levar uma mensagem de Cristo através da nossa música. 
            Nosso som é uma mistura de rock clássico com influências modernas, criando uma experiência única para nossos ouvintes.
            <br></br> <br></br>
            Formada em 2023, a banda já tocou em vários eventos, igrejas... 
            Nosso objetivo é inspirar e impactar vidas ao redor do mundo com nossas letras, que são completamente voltadas ao cristianismo, e melodias.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Sobre;