import React, { useState, useEffect } from 'react';
import './Fotos.css';

const images = [
  './assets/foto1.jpg',
  './assets/foto2.jpg',
  './assets/foto3.jpg',
  './assets/foto4.jpg',
  './assets/foto5.jpg',
  './assets/foto6.jpg',
  './assets/foto7.jpg',
  './assets/foto8.jpg',
  './assets/foto9.jpg'
];

const Fotos = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="carousel-container">
      <div className="carousel">
        <button className="carousel-control prev" onClick={prevImage}>
          &lt;
        </button>
        <img src={images[currentImageIndex]} alt="Banda" className="carousel-image" />
        <button className="carousel-control next" onClick={nextImage}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Fotos;