import React from 'react';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <div className="home-content">
        <h1>Banda Excelsu</h1>
        <i>Significado: Sublime, Eminente, Destacado, Elevado...</i>
        <p>Somos uma banda de rock cristão comprometida em compartilhar mensagens positivas e inspiradoras através da nossa música. 
          Nossa jornada começou em 2023, e desde então, temos tocado e ministrados em diversos eventos e igrejas.
          Nosso foco, e nosso alvo, é divulgar a palavra transformadora, e salvadora de Jesus Cristo, através do nosso som pesado, e ao mesmo tempo suave.
        </p>
      </div>
    </div>
  );
}

export default Home;
